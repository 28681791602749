import {
  WppActionButton,
  WppGrid,
  WppIconChevron,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { Link, useParams } from 'react-router-dom'
import xss from 'xss'

import { useNewsByIdApi } from 'api/news/queries/useNewsByIdApi'
import { ImagePlaceholder } from 'components/imagePlaceholder/imagePlaceholder'
import styles from 'pages/newsViewer/NewsViewer.module.scss'
import { NewsById } from 'types/news/newsById'
import { formatDate } from 'utils/dates'

export const NewsViewer = () => {
  const { newsId } = useParams()
  const { osContext } = useOs()
  const {
    tenant: {
      azMeta: { organizationsId },
    },
  } = osContext

  const { isLoading, data } = useNewsByIdApi({
    params: {
      newsId,
      tenantId: organizationsId,
    },
  })

  const content = (isLoading: boolean, data: NewsById | null) => {
    if (isLoading) {
      return (
        <div className={styles.center}>
          <WppSpinner size="m" />
        </div>
      )
    } else if (!data) {
      return (
        <div className={styles.center}>
          <WppTypography type="m-strong">Not found</WppTypography>
        </div>
      )
    } else {
      return (
        <WppGrid container fullWidth justifyContent="center">
          <WppGrid item all={12}>
            <div className="container">
              {data.banner_image ? (
                <img className={styles.image} src={data?.banner_image} alt="News Cover" />
              ) : (
                <ImagePlaceholder />
              )}
              <div className={styles.author}>
                <WppTypography type="m-midi">{data?.authors}</WppTypography>
              </div>
              <div className={styles.title}>
                <WppTypography type="3xl-heading">{data?.title}</WppTypography>
              </div>
              <div className={styles.date}>
                <WppTypography type="m-body" data-testid="date-label">
                  {formatDate(data?.created_at || '', osContext.userDetails.dateLocale)}
                </WppTypography>
              </div>
              <div className={styles.content}>
                <WppTypography type="l-body">
                  <div dangerouslySetInnerHTML={{ __html: xss(data?.content || '') }} />
                </WppTypography>
              </div>
            </div>
          </WppGrid>
        </WppGrid>
      )
    }
  }

  return (
    <>
      <Link className={styles.backButtton} to="news">
        <WppActionButton>
          <WppIconChevron direction="left" slot="icon-start" />
          Back to News
        </WppActionButton>
      </Link>
      {content(isLoading, data)}
    </>
  )
}
